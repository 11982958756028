import React, { useContext, useEffect, useState } from "react"
import { graphql, PageProps } from "gatsby"
import { Layout } from "../components/atoms/Layout"
import { SEO } from "../components/atoms/SEO"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import { DisclaimersContext } from "../contexts/Disclaimers"
import { CTA, Dealer } from "../global"
import { getCTALinkValue, parseDisclaimerBlocks } from "../helpers"
import { ButtonLink } from "../components/atoms/Button"
import tw from "twin.macro"
import useTealiumEvent from "../hooks/Tealium/useTealiumEvent"
import { toggleDisclaimersModal } from "../contexts/Disclaimers/actions"
import { DealersClient } from "../clients/DealersClient"

const StJohnsTownCenterPage: React.FC<PageProps> = ({
  data,
  location,
}: any) => {
  const { page } = data

  const { handleTealView } = useTealiumView()
  const [state, dispatch] = useContext(DisclaimersContext)
  const [dealers, setDealers] = useState([])
  // Tealium
  const { trackTealEvent } = useTealiumEvent()

  const getDealers = async () => {
    const dealersInfo: Dealer[] = await DealersClient.getForLocation(
      32246,
      100,
      5
    )
    setDealers(dealersInfo)
  }

  useEffect(() => {
    getDealers()
  }, [])

  const getDealersCode = (name: string) => {
    const code = dealers.find(dealer => dealer.Name === name)?.DealerCode
    return code || ""
  }

  useEffect(() => {
    handleTealView({
      page_name: "qr_landing_st_johns",
      page_type: "find your dealer",
      tealium_event: "dealer_qr_landing",
    })
  }, [])
  return (
    <Layout>
      <SEO
        url={location.href}
        title={page.title}
        keywords={page.seo?.keywords}
        description={page.seo?.description}
        canonicalPath={page.seo?.canonicalPath}
      />
      <div css={[tw`my-20`]}>
        <h1 css={[tw`text-center text-xl font-semibold mb-10`]}>
          {parseDisclaimerBlocks(page.heading, selection =>
            dispatch(toggleDisclaimersModal(selection))
          )}
        </h1>
        <ul
          css={[
            tw`flex flex-col justify-center items-center gap-5 max-w-md mx-auto px-3`,
          ]}
        >
          {page.dealerLinks &&
            page.dealerLinks.map((cta: CTA, index: number) => {
              const link = getCTALinkValue(cta)
              const dealerCode = getDealersCode(cta.title)
              return (
                <li css={[tw`w-full`]} key={cta._key}>
                  <ButtonLink
                    to={link}
                    target={cta?.linkType === "external" ? "_blank" : "_self"}
                    key={cta?.title}
                    {...{ [cta?.buttonType]: true }}
                    analytics-id={`visit website:dealers:${index}`}
                    //Cta button type = secondary change background color to white 90% opacity  and text color to black
                    css={[
                      tw`w-full`,
                      cta?.buttonType === "secondary" &&
                        tw`bg-white bg-opacity-90 text-black`,
                    ]}
                    onClick={() => {
                      trackTealEvent({
                        tealium_event: "visit_dealer_website",
                        dealer_name: cta?.title,
                        dealer_code: dealerCode,
                      })
                    }}
                  >
                    {cta?.title}
                  </ButtonLink>
                </li>
              )
            })}
        </ul>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query StJohnsTownCenterPageQuery($id: String) {
    page: sanityPageStJohnsTownCenter(id: { eq: $id }) {
      id
      language
      title
      heading
      dealerLinks {
        _key
        title
        buttonType
        linkType
        externalUrl
        internalLink {
          slug {
            current
          }
        }
      }
      _id
    }
  }
`

export default StJohnsTownCenterPage
